/**
 * Page for the conclusion of the volunteer submission.
 */

import React from 'react';
import { Link, graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import { localizer } from '../util/localizer';
import Nav from '../components/Nav';
import { setLinks } from '../util/pagetools';
import Footer from '../components/FooterSimple';

export default function ThankYou(props) {
  const ctx = props.pageContext;
  const sitedata = props.data.siteYaml;
  sitedata.language = ctx.locale;
  const toplinks = setLinks(sitedata.pagelinks);
  const sitename = sitedata.sitename;
  const { siteurl, language } = sitedata;
  const footer = { ...sitedata.footer, toplinks, sitename, siteurl }

  return (
    <div id="page">
      <Helmet htmlAttributes={{ lang: ctx.locale }}>
        <meta charSet="utf-8" />
        <title>{sitedata.sitename}</title>
        <link rel="canonical" href={sitedata.siteurl} />
        <meta name="description" content={sitedata.metaDescription}></meta>
      </Helmet>
      {props.header && <header>{props.header}</header>}
      {!props.header &&
        <header>
          <Nav toplinks={toplinks} sitename={sitedata.sitename} localizerfn={localizer(ctx)} />
        </header>
      }
      <main id="thank-you" className="container">
        <div className="row">
          <div className="col-sm-8 col-sm-offset-2">
            <h1 id="thankyou-title" className="text-pary-dark">Thank you for signing up!</h1>
            <p>You will now receive emails notifying you about new clinical trial enrollment opportunities and clinical trial news.</p>
            <p>We will provide you info on new clinical trial opportunities that might be of interest for you. These opportunities may or may not be directly affiliated with WCG.</p>
            <p>After you get the news, you still need to be confirmed for eligibility. Please consult your physician. This does not constitute medical advice.</p>
            <p><a href="/" className="std-btn btn-white-on-pary">Back to Home</a></p>
          </div>
        </div>
      </main>
      <Footer src={footer} localizerfn={localizer(ctx)} />
    </div>
  );
};

export const query = graphql`
  query siteThankYou($locale: String, $variation: String) {
    siteYaml(locale: {eq: $locale}, variation: {eq: $variation}) {
      sitename
      locale
      variation
      errorpage {
        appError
      }
      navbar {
        theme
      }
      pagelinks {
        title
        linkto
      }
      sections {
        title
        linkto
      }
      footer {
        theme
        text
        links {
          label
          to
          newtab
        }
      }
    }
  }`;
